<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'EXPENDITURE_TYPE_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('EXPENDITURE_TYPE')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <div>
      <!-- List page filter -->
      <expenditure-type-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                           v-model="filter"></expenditure-type-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="expenditure-type-table"
        ref="expenditureTypeTable"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('EXPENDITURE_TYPES_LABEL')})  | capitalize }}</strong>
        </div>
      </template>

      <!-- name -->
      <template #head(name)="">
        <span>{{ labels.name | capitalize }}</span>
      </template>

      <template #cell(name)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.name }}</span>
        </div>
      </template>
      <!-- name -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'EXPENDITURE_TYPE_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'EXPENDITURE_TYPE_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'EXPENDITURE_TYPE_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'EXPENDITURE_TYPE_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="expenditure-type-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import eventBus from "@/events";
import ExpenditureTypeListFilter from "@/expenditureType/ExpenditureTypeListFilter";
import PageSize from "@/components/PageSize";
import ExpenditureTypes from "@/expenditureType/index";

export default {
  name: "ExpenditureTypesList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, ExpenditureTypeListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage],
  mounted() {
    eventBus.$on('LIST_COUNT_EXPENDITURE_TYPE_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {},
  data() {
    return {
      labels: {
        code: this.$t('EXPENDITURE_TYPE_CODE_LABEL'), name: this.$t('EXPENDITURE_TYPE_NAME_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('EXPENDITURE_TYPES_LABEL'),
        excel: this.$t('EXPENDITURE_TYPES_LABEL') + '.xls',
      },
      registerPage: 'EXPENDITURE_TYPE_REGISTER_TITLE',
      editPage: 'EXPENDITURE_TYPE_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return ExpenditureTypes;
    },
    fields() {
      return [
        {key: 'name', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.expenditureTypeTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.name] = 'name'
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
